import { inject, Injectable, signal } from '@angular/core';
import { ApiService } from '@core/api/api.service';
import { AndroidApp } from '@root/app/shared/android-apps.types';
import { finalize, tap } from 'rxjs';

import { UserStore } from '../../user/user.store';

export interface DeviceAppsUpdate {
	appId: string;
	packageName: string;
	status: number;
	internetConfig: number;
}

@Injectable({
	providedIn: 'root',
})
export class AppsService {
	readonly #store = inject(UserStore);
	private apiService = inject(ApiService);

	isLoading = signal<boolean>(false);

	getAllApps() {
		this.isLoading.set(true);

		return this.apiService
			.get<AndroidApp[]>('/android/apps')
			.pipe(tap(() => this.isLoading.set(false)));
	}

	updateStatus(deviceId: string, data: DeviceAppsUpdate) {
		this.isLoading.set(true);

		return this.apiService
			.put<void>(`/android/device/appstatus/${deviceId}`, data, {
				params: { access: this.#store.selectedGroup() },
			})
			.pipe(finalize(() => this.isLoading.set(false)));
	}

	removeAppValues(deviceId: string, appId: string) {
		return this.apiService.delete(`/android/device/appvalues/${deviceId}`, { params: { appId } });
	}

	updateVpnMode(deviceId: string, allow: boolean) {
		this.isLoading.set(true);

		return this.apiService
			.put<void>(`/android/device/updatevpnmode/${deviceId}`, { params: { allow } })
			.pipe(finalize(() => this.isLoading.set(false)));
	}
}
